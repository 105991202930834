@media (max-width: 992px) {
  .main-logo img{height: 50px !important; margin: 0; padding: 0;}
  .registration-link{position: fixed; right: 0; top: 30%; z-index: 99;}
  .menuButton{ position: absolute !important; right: 0; top: 0;}
  .menuButton svg{color: #000;}

h2, .inner-banner h1{font-size: 32px !important;}
body, p{font-size: 14px; line-height: 22px;}
.vision-mission-card{height: auto;}
.vm-card{display: block !important; text-align: center;}
.vision-mission-image{width: 100px; margin: 0 auto 15px auto; display: block;}
.vision-mission-card{border: none !important;}
.whyandhow, .testimonials-section,   .home-about {text-align: center !important;}
.whyandhow img{width: 100% !important; height: auto !important;}
.typography-body{padding: 0 !important;}
.testimonials-section{padding:50px 0 150px 0 !important;}
.footer-logo{width: 200px !important; margin: 0 auto !important;}
.footer-heading{border-bottom: solid 1px #fff; padding-bottom: 10px; margin-bottom: 15px !important;}
.inner-banner {

    padding: 25px 0 15px 0;
}
.fullWidth{width: 100%;}
.halfWidth{width: 46%;}
.internship-page  .registration-link{display: none !important;}
}