header{padding: 10px 0; background: var(--bgTheme) !important;}
.mainNav .navItem .navLink {color: var(--fontColor);}

.subnav{position: absolute; left: 0; top: 100%; z-index: 999; min-width: 300px; background: #fff;}

.mainNav .navItem .navLink svg{ display: inline-block ; vertical-align: top;}

.subnav button{display: block; width: 100%; border-bottom: solid 1px #ccc; padding: 0 10px; border-radius: 0; text-align: left; }

.subnav button:last-child{border-bottom: none;} 
