/* General Footer Styles */
footer {
    background-color: #2c52a3;
    color: #fff;
    padding: 50px 0 0 0;
  }
  footer p{color: #fff;}

  .footer-logo  {
    width: 150px !important;
 
  }
  
  .footer-social-icons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .footer-bottom p{color: #fff !important;}
  
  @media (min-width: 900px) {
    .footer-social-icons {
      justify-content: flex-start;
    }
  }
  
  .footer-social-icons a {
    color: white;
  }
  
  /* Typography */
  .footer-heading {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .footer-contact-description {
    margin-top: 8px;
  }
  
  /* List Styles */
  .footer-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-list li {
    margin-bottom: 8px;
  }
  
  .footer-list li a {
    color: inherit;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-list li a:hover {
    text-decoration: underline;
  }
  
  /* Bottom Section */
  .footer-bottom {
    background-color: #f9f9f9;
    color: #000;
    padding: 20px 0;
    margin-top: 40px;
  }
  
  .footer-bottom a {
    color: inherit;
    text-decoration: none;
  }
  

  .list-arrow{margin: 0; padding: 0;}
  .list-arrow li{margin: 0 0 15px 0; padding: 0;}
  .list-arrow li a{display: block; padding: 0 0 0 20px;}
  .list-arrow li a::before{content: ""; position: absolute; left: 0; top: 0;
    width: 0; 
    height: 0; 
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    
    border-left: 8px solid rgb(255, 255, 255);

}
  .half-list li{width: 48%; display: inline-block; vertical-align: top;}

  .footer-bottom{background: #122b60;}