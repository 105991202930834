.serviceCard {
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.serviceCard:hover {
    background-color: #2c52a3;
    color: white;
}

.serviceCard:hover .serviceImage {
    filter: brightness(0) invert(1);
}

.serviceCard:hover .MuiTypography-root {
    color: white;
}

/* Additional styling to adjust text and icons on hover */
.imageCircle {
    width: 100px;
    height: 100px;
    padding: 10px;
    margin: 0 auto 20px auto;
    border-radius: 50%;
    border: 2px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
}

.serviceImage {
    width: 80px;
    height: 80px;
    margin: 0 auto;
}
