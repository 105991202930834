/* VisionMissionCard.css */
.vision-mission-section {
    background-color: #f5f5f5;
    padding: 40px 0;
  }
  
  .vision-mission-heading {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .vision-mission-card {
    border-left: 5px solid;
    border-color: #2c52a3;
    height: 300px;
  }
  
  .vision-mission-image {
    width: 100%;
  }
  
  .vision-mission-content {
    flex-grow: 1;
  }
  