.clubCard {
    transition: background-color 0.3s ease, color 0.3s ease;
}

.clubCard:hover {
    background-color: #2c52a3;
    color: white;
}

.clubCard:hover .clubImage {
    filter: brightness(0) invert(1);
}

.clubCard:hover .MuiTypography-root, .clubCard:hover p {
    color: white;
}

