
/* In your custom.css file */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

/* ---------------defualt styles------------------------ */



:root {
    --bgTheme: #ffffff; 
    --colorPrimary: var(--primay-color); 
    --colorSecondary: var(--secondary-color); 
    --colorTertiary: var(--tertiary-color); 
    --linkColor: var(--link-color); 
    --fontColor: var(--font-color); 
    --cardColor: #ffffff;
  }
  
  /* Define dark theme variables */
  [data-theme="dark"] {
    --bgTheme: var(--dark-theme-color); 
    --colorPrimary: var(--secondary-color); 
    --colorTertiary: var(--tertiary-color); 
    --fontColor: #ffffff;
    --linkColor:  #bbb8b8;
    --cardColor: var(--dark-card-color)
  }
  
  /* Apply variables in styles */
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 400;
    background: var(--bgTheme);
    color:var(--fontColor);
  }

input{border:solid 1px var(--linkColor) !important; color: var(--fontColor) !important;}
.MuiInputLabel-root, .css-19ihawg-MuiPaper-root-MuiCard-root, .MuiTypography-h5, .MuiTypography-body1, p
{ color: var(--fontColor) !important;}
textarea{background: none !important; color: var(--fontColor) !important;}
.MuiCard-root{background: var(--cardColor) !important;}


a{text-decoration: none; color: var(--fontColor);}
a:hover{color: var(--linkColor);}

p{font-size: 16px; line-height: 24px; color: #000;}
.btn{text-decoration: none; background: #eee; display: inline-block; padding: 10px 25px; color: #000; border-radius: 4px; font-size: 18px; line-height: 22px;}
.img-fluid{max-width: 100%; height: auto;}
.w-100{width: 100%;}
.h-100{height: 100%;}
.btn-primary{background: #2c52a3; border-color:#2c52a3  ; color: #fff;}
.font300{font-weight: 300 !important;}
.font400{font-weight: 400 !important;}
.font500{font-weight: 500 !important;}
.font700{font-weight: 700 !important;}
section{padding: 3rem 0;}
.icon-list{margin: 0; padding: 0;}
.icon-list li a{color: #000; text-decoration: none; display: block; padding-left: 40px; margin-bottom: 5px; position: relative; font-size: 18px; line-height: 30px;}
.icon-list li a svg{position: absolute; left: 0; top: 0;}

footer .icon-list li a{color: #fff;}
.internship-page form label{background: #fff;}
.mute-text{font-size: 14px !important; color: #555;}
/* ---------------defualt styles end------------------------ */


.text-primary{color: var(--colorPrimary);}
.MuiInputLabel-formControl{background: #fff;}
.MuiTextField-root{margin-bottom: 0 !important;}
.custom-btn{padding: 18px 30px !important; font-size: 18px !important; line-height: 22px !important;}
.btn-primary{background:  var(--colorPrimary) !important; border-color:  var(--colorPrimary) !important;}
.btn-secondary{background:  var(--colorSecondary) !important; border-color:  var(--colorSecondary) !important;}

.event-card{position: relative;}
.postion-text{position: absolute; right: 0; top: 0; background: var(--primay-color); color: #fff !important; padding: 3px 10px; font-size: 12px !important;}
.dateBlock svg{display: inline-block; width: 16px; vertical-align: top; position: relative; fill: var(--primay-color);  top: -4px; margin-right: 5px;}
.dateBlock p{font-size: 12px !important; }
.dateBlock p:last-child{margin-left: 7px;}




.shadow-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

}


.shadow-2 {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.shadow-3 {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.shadow-4 {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.shadow-5 {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}




.MuiCard-root.shadow-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24) !important;

}


.MuiCard-root.shadow-2 {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) !important;
}

.MuiCard-root.shadow-3 {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23) !important;
}

.MuiCard-root.shadow-4 {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22) !important;
}

.MuiCard-root.shadow-5 {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22) !important;
}

.custom-list{margin: 0; padding: 0;}
.custom-list li{padding: 8px 0 10px 25px !important; margin: 0 0 15px 0 !important; position: relative; border-bottom: solid 1px #ccc;}
.custom-list li svg{position: absolute; left: 0; top: 5px; fill: var(--primay-color);} 
.custom-list li a{ display: inline-block; vertical-align: top; margin-left: 10px;}