/* Whyandhow.css */
.whyandhow-container {
    padding: 2rem;
    margin: auto;
    max-width: 100%;
    max-height: auto;
    flex-grow: 1;
    background-color: #fff;
  }
  
  .whyandhow-container.dark {
    background-color: #1A2027;
  }
  
  .whyandhow-image-wrapper {
    width: 600px;
    height: 600px;
    object-fit: cover;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .typography-heading {
    border-bottom: 2px solid black;
    display: inline-block;
    padding: 1rem;
    margin-left: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .typography-body {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    padding: 1rem;
    margin-left: 1.5rem;
  }
  
  .button-read-more {
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 2px;
    margin-left: 1.5rem;
  }
  
  @media screen and (max-width: 600px) {
    .typography-body {
      font-size: 0.865rem;
    }
  }
  