.testimonials-section {
    background: #053e88;
    position: relative;
    padding: 100px 0 250px 0;
}

.testimonials-section .divider {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
    z-index: 1;
}

.testimonials-section .container {
    position: relative;
    z-index: 2;
}

.testimonials-section p {
    color: #fff;
}

/* Modal styling for video playback */
.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    background: white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    padding: 16px;
    border-radius: 8px;
}
.postion-relative{position: relative; z-index: 2;}
.testimonials-section p,  .testimonials-section  .MuiTypography-h5{color: #fff !important;}